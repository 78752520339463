<template>
  <validation-observer slim v-slot="{ invalid }">
    <v-dialog
      :value="showDialog"
      content-class="travel-update-dialog"
      :persistent="false"
      width="50vw"
      @click:outside="closeDialog"
      @input="closeDialog"
    >
      <v-card class="travel-update-card">
        <v-card-title class="headline">
          <span>Редактирование информации о поездке</span>
        </v-card-title>
        <v-card-text class="travel-update-content">
          <form>
            <ValidationProvider name="question" rules="required" v-slot="{ invalid, validated }">
              <v-text-field
                v-model="travelItem.title"
                :error="invalid && validated"
                label="Название *"
                hide-details="auto"
                required
              />
            </ValidationProvider>
            <ValidationProvider
              name="question"
              rules="required"
              v-slot="{ invalid, validated }"
            >
              <v-text-field
                label="Символьный код *"
                class="mt-4"
                v-model="travelItem.url"
                hide-details="auto"
                required
                :error="invalid && validated"
              />
            </ValidationProvider>
            <div class="mt-4">
              <v-menu
                transition="scale-transition"
                :close-on-content-click="false"
                offset-y
                min-width="auto"
              >
                <template #activator="{ on, attrs }">
                  <v-text-field
                    label="Выберите активные даты поездок:"
                    hide-details="auto"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  />
                </template>
                <v-date-picker
                  v-model="createdDates"
                  multiple
                  locale="ru-RU"
                  :value="createdDates"
                  :allowed-dates="allowedDates"
                />
              </v-menu>
            </div>
            <div class="mt-4">
              <v-chip
                v-for="date in travelItem.visit_dates"
                outlined
                class="mr-2 mb-2"
                close
                :key="date"
                close-icon="mdi-delete"
                @click:close="deleteDateHandle(date)"
              >
                {{ formatDate(date) }}
              </v-chip>
              <v-chip
                v-for="date in createdDates"
                :key="date"
                class="mr-2 mb-2"
                close
                color="green"
                text-color="white"
                @click:close="deleteCreatedDateHandle(date)"
              >
                {{ formatDate(date) }}
              </v-chip>
              <v-chip
                v-for="date in deletedDates"
                :key="date"
                color="red"
                close
                close-icon="mdi-backup-restore"
                class="mr-2 mb-2"
                text-color="white"
                @click:close="revertDeletedDateHandle(date)"
              >
                {{ formatDate(date) }}
              </v-chip>
            </div>
            <v-layout column class="mt-4">
              <ck-editor
                editor-url="/js/ckeditor/ckeditor.js"
                v-model="travelItem.static_page_content"
                :config="editorConfig"
              />
            </v-layout>
            <div class="html-button">
              <button
                type="button"
                class="btn btn-secondary"
                @click="toggleCodeEditorHandle"
              >
                <span>{{ openCodeEditor ? 'Скрыть код' : 'Показать код' }}</span>
              </button>
            </div>
            <Transition>
              <div v-if="openCodeEditor">
                <h2 class="h4 mt-5">HTML Source</h2>
                <textarea class="my-4 form-control" v-model="travelItem.static_page_content" rows="50" />
              </div>
            </Transition>
          </form>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <CButton class="mr-3" color="secondary" @click="closeDialog">
            Отмена
          </CButton>
          <CButton :disabled="invalid" color="success" @click="submit">
            <i class="fa fa-save mr-2"></i>
            <span>Сохранить</span>
          </CButton>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </validation-observer>
</template>

<script>
import moment from 'moment'
import cloneDeep from "lodash.clonedeep";
import CkEditor from 'ckeditor4-vue'
import EntityModalMixin from "@/views/grid_elements/mixins/EntityModalMixin";
import {ValidationProvider, ValidationObserver} from 'vee-validate';
import api from "@/api";

export default {
  name: 'TravelUpdateModal',
  components: {
    ValidationProvider,
    ValidationObserver,
    CkEditor: CkEditor.component,
  },
  mixins: [EntityModalMixin],
  data() {
    return {
      travelItem: {
        id: '',
        static_page_id: '',
        title: '',
        url: '',
        static_page_content: '',
        visit_dates: [],
      },
      createdDates: [],
      deletedDates: [],
      openCodeEditor: false,
      editorConfig: {
        allowedContent: true,
        height: '34vh',
        language: 'ru',
        readOnly: false,
      },
    }
  },
  methods: {
    updateEntity: api.travels.updateVisit,
    prepareUpdateEntityParams() {
      return [this.travelItem.id, {
        ...this.travelItem,
        created_dates: this.createdDates,
        deleted_dates: this.deletedDates,
      }];
    },
    formatDate(date) {
      return moment(date).format('DD-MM-YYYY');
    },
    allowedDates(val) {
      return moment(val).isAfter(moment(new Date()).add('day', 10)) &&
        !this.deletedDates.includes(val) &&
        !this.travelItem.visit_dates.includes(val);
    },
    toggleCodeEditorHandle() {
      this.openCodeEditor = !this.openCodeEditor;
    },
    deleteCreatedDateHandle(date) {
      this.createdDates = this.createdDates.filter((createdDate) => createdDate !== date);
    },
    revertDeletedDateHandle(date) {
      this.deletedDates = this.deletedDates.filter((deletedDate) => deletedDate !== date);
      this.travelItem.visit_dates.push(date);
    },
    deleteDateHandle(date) {
      this.travelItem.visit_dates = this.travelItem.visit_dates.filter((existDate) => existDate !== date);
      this.deletedDates.push(date);
    },
  },
  watch: {
    showDialog(showDialogValue) {
      if (showDialogValue) {
        this.createdDates = [];
        this.deletedDates = [];
        this.travelItem = cloneDeep({ ...this.currentItem });
      }
    },
  }
}
</script>

<style lang="sass">
.travel-update-dialog
  height: 100%
.travel-update-card
  display: flex
  flex-direction: column
  height: 100%
.travel-update-content
  overflow: auto
  flex: 1
.html-button
  margin: 15px 0
  width: 250px
.available-date-picker-label
  color: rgba(0, 0, 0, .87)
  font-size: 14px

</style>
